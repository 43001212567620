import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MarketingOrderContacts, MarketingOrderContactService, ProfileInfo, MarketingOrder, ContentKeys, ContentfulService } from '@lc/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'lc-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnChanges, OnDestroy {
  marketingOrderId: string;
  private genericPhNumSub: Subscription;


  @Input()
  showAgent: boolean = true;

  @Input()
  showCoLister: boolean = true;

  @Input()
  showCoordinator: boolean = true;

  @Input()
  showTeam: boolean = true;

  @Input()
  isCollapsed: boolean = false;

  @Input()
  isDetailExpand:boolean = false;

  @Input()
  marketingOrder: MarketingOrder;

  isLoading: boolean;
  contactInfo$: Observable<MarketingOrderContacts>;

  genericCoordinator = { phoneNumber: '1.833.LIST.CON', email: 'lc@listing-concierge.com'} as ProfileInfo;

  constructor(private contactInfoService: MarketingOrderContactService,
    public contentfulService: ContentfulService) {
    const genericPhNum$ = this.contentfulService.getContent$(ContentKeys.GENERIC_PHONE_NUMBER, '1.833.LIST.CON');
    this.genericPhNumSub = genericPhNum$.subscribe(val => {
      this.genericCoordinator.phoneNumber = val;
    }, (error) => { throw new Error(error); });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && this.marketingOrder) {
      this.isLoading = true;
      this.marketingOrderId = this.marketingOrder._id;
      this.contactInfo$ = this.contactInfoService.getContactInfo(this.marketingOrderId)
        .pipe(tap(() => this.isLoading = false)
      );
    }
  }

  /** Executes when the directive is destroeid */
  ngOnDestroy() {
    this.genericPhNumSub?.unsubscribe();
  }

}
