<ng-template #loadingTemplate>
  <ngx-loading [show]="isLoading" [config]="{}"></ngx-loading>
</ng-template>

<div class="py-2" *ngIf="contactInfo$ | async as contacts; else loadingTemplate">
  <div class="row m-0 justify-content-center" *ngIf="!isCollapsed">
    <span class="text-center font-weight-bold">Have a question about this order?</span>
  </div>

  <div class="row m-0" *ngIf="isDetailExpand">
    <div class="detail-section">
        <span class="font-weight-bold">Questions about your order?</span>
        <span class="detai-inline">Contact the Listing Concierge Team at {{genericCoordinator.phoneNumber}} or lc@listing-concierge.com </span>
    </div>

    <div class="row m-0 justify-content-center" *ngIf="!isDetailExpand">
      <span class="text-center">Contact: </span>
    </div>
  </div>

  <!-- Agent Display -->
  <ng-container *ngIf="showAgent && contacts.agent?._id" [ngTemplateOutlet]="contactInfo"
    [ngTemplateOutletContext]="{info:contacts.agent}">
  </ng-container>

  <!-- CoLister Display -->
  <ng-container *ngIf="showCoLister && contacts.coListingAgent?._id" [ngTemplateOutlet]="contactInfo"
    [ngTemplateOutletContext]="{info:contacts.coListingAgent}">
  </ng-container>

  <!--Team Member(s) Display-->
  <ng-container *ngIf="showTeam && contacts.team" [ngTemplateOutlet]="teamInfo"
    [ngTemplateOutletContext]="{info:contacts.team,agentId:contacts.agent?.userId}">
  </ng-container>

  <!-- Coordinator Display-->
  <ng-container *ngIf="showCoordinator">
    <ng-container *ngIf="contacts.coordinator else genericCoordinatorTemplate" [ngTemplateOutlet]="contactInfo"
      [ngTemplateOutletContext]="{info:contacts.coordinator, isCoordinator: true}">
    </ng-container>
  </ng-container>

  <ng-template #genericCoordinatorTemplate>
    <ng-container [ngTemplateOutlet]="contactInfo" [ngTemplateOutletContext]="{info:genericCoordinator}">
    </ng-container>
  </ng-template>

</div>

<!-- Template used to display contact info-->
<ng-template #contactInfo let-info='info' let-coordinator="showCoordinator" >
  <div class="contact-info" [ngbPopover]="popover" container="body">
    <!-- Display contact details container-->
    <ng-container *ngIf="!isCollapsed && showCoordinator" [ngTemplateOutlet]="contactDetails"
      [ngTemplateOutletContext]="{info:info}"></ng-container>
    <ng-container *ngIf="!isCollapsed && !showCoordinator" [ngTemplateOutlet]="contactDetailsHorz"
      [ngTemplateOutletContext]="{info:info}"></ng-container>

    <hr *ngIf="!showCoordinator">

    <!-- Popover for agent contact details-->
    <ng-template #popover>
      <ng-container [ngTemplateOutlet]="contactDetails"
        [ngTemplateOutletContext]="{info:info, showCoordinator: showCoordinator}"></ng-container>
    </ng-template>
  </div>
</ng-template>


<!-- Used in the popover and in the expanded version-->
<ng-template #contactDetails let-info="info" let-coordinator="showCoordinator" let-primaryAgent="primaryAgent">
  <!-- Display Profile photo -->
  <div class="row m-0 justify-content-center py-2" *ngIf="info">
    <img alt="" id="avatar" class="rounded-image profile-photo" [ngClass]="isCollapsed ? 'small' : 'medium'"
      [src]="info?.profilePhotoUrl || info?.photoUrl || 'assets/images/user.png'">
  </div>

  <div class="row m-0 justify-content-center">
    <span class="text-center font-weight-bold full-name overflow-ellipsis">{{info?.firstName}} {{info?.lastName}}</span>
  </div>

  <div *ngIf="info._id && info.phoneNumber" class="row m-0 justify-content-center">
    <span class="text-center phone overflow-ellipsis">{{ info?.phoneNumber | phone }} </span>
  </div>

  <div *ngIf="info._id && info.email" class="row m-0 justify-content-center">
    <span class="text-center email overflow-ellipsis">{{ info?.email }} </span>
  </div>

<!-- for popover cordinator officename will shown below -->
  <div *ngIf="info._id && info.officeName && info.userId===primaryAgent" class="row m-0 justify-content-center">
    <span class="text-center email overflow-ellipsis">{{ info?.officeName }} </span>
  </div>

  <div *ngIf="showCoordinator && info" class="row m-0 justify-content-center">
    <span class="text-center generic-phone overflow-ellipsis">{{genericCoordinator.phoneNumber}}</span>
  </div>

  <div *ngIf="!info._id || (showCoordinator && !info.email)" class="row m-0 justify-content-center">
    <span class="text-center generic-email overflow-ellipsis">{{genericCoordinator.email}}</span>
  </div>
</ng-template>

<ng-template #contactDetailsHorz let-info="info" let-primaryagent="primaryAgent">
  <div class="member-wrapper">
    <!-- Display Profile photo -->
    <div class="member-avatar justify-content-center">
      <img alt="" id="avatar" class="rounded-image profile-photo" [ngClass]="isCollapsed ? 'small' : 'medium'"
        [src]="info?.profilePhotoUrl || info?.photoUrl || 'assets/images/user.png'">
    </div>
    <div class="member-data">
      <div class="font-weight-bold full-name overflow-ellipsis">{{info.firstName}} {{info.lastName}}</div>
      <div class="phone text-faded overflow-ellipsis">{{info.phoneNumber | phone }}</div>
      <div class="metro text-faded overflow-ellipsis">{{info.metroName}}</div>
      <div class="member-email email text-faded overflow-ellipsis">{{info.email}}</div>
      <div class="office-name text-faded overflow-ellipsis" *ngIf="!primaryagent">{{info.officeName}}</div>
    <!-- Display primary agent office name during team members display -->
      <div class="office-name text-faded overflow-ellipsis" *ngIf="primaryagent === info.userId">{{info.officeName}}</div>
    </div>
    <a mat-icon-button class="xs member-edit" color="default" [routerLink]="['/manage-order/' + marketingOrderId + '/profile/' + info?._id]" (click)="$event.stopPropagation()">
      <mat-icon>edit</mat-icon>
    </a>
  </div>
</ng-template>

<!-- Template used to display team-->
<ng-template #teamInfo let-info='info' let-agentId="agentId">
  <div class="team-info">
    <!-- Display contact details container-->
    <ng-container *ngIf="!isCollapsed" [ngTemplateOutlet]="teamDetails" [ngTemplateOutletContext]="{info:info,agentId:agentId}">
    </ng-container>
  </div>
</ng-template>

<!-- Used in the popover and to show team details-->
<ng-template #teamDetails let-info="info" let-agentId="agentId">
  <div fxLayout="row" class="row mx-1" fxLayoutAlign="space-between">
    <!-- Team Contact Information-->
    <div fxLayout="column" class="team-contact">
      <span class="text-left font-weight-bold full-name overflow-ellipsis">{{info?.name}}</span>
      <span class="text-left phone overflow-ellipsis">{{ info?.phoneNumber| phone }} </span>
      <span *ngIf="info.email" class="text-left email overflow-ellipsis">{{ info?.email }} </span>
    </div>

    <!-- Edit Team button -->
    <a mat-icon-button class="xs team-edit" color="default" [routerLink]="['/manage-order/' + marketingOrderId + '/team/' + info?.id]" (click)="$event.stopPropagation()">
      <mat-icon>edit</mat-icon>
    </a>
  </div>
  <div class="row my-2 m-0 pull-left">
    <span class="font-weight-bold">Members: </span>
  </div>
  <div *ngFor="let aMember of info?.members">
    <div class="contact-info clickable" [ngbPopover]="popover">
      <ng-container [ngTemplateOutlet]="contactDetailsHorz" [ngTemplateOutletContext]="{info:aMember,primaryAgent:agentId}">
      </ng-container>

      <!-- Popover for agent contact details-->
      <ng-template #popover>
        <ng-container [ngTemplateOutlet]="contactDetails" [ngTemplateOutletContext]="{info:aMember,primaryAgent:agentId}"></ng-container>
      </ng-template>
    </div>
    <hr>
  </div>
</ng-template>

<ng-template #detailExpand><span class="text-center font-weight-bold">Questions about your order?</span></ng-template>
