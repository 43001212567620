import { Directive, Input, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { PackageInstance } from '@lc/core';

@Directive({
  selector: '[lcBadge]'
})
export class BadgeDirective implements OnChanges {

  /** The package to locate the badge for */
  @Input('lcBadge') package: PackageInstance;

  /** The badge size */
  @Input() isSmall: boolean;

  /** Host binding that sets the image source */
  @HostBinding('src') source: string;


  /** Host binding that sets the alt the elemenet */
  @HostBinding('alt') alt: string;

  ngOnChanges(changes: SimpleChanges) {
    if(changes.package && this.package) {
      this.source = this.getBadge();
      this.alt = `${this.package.title.toLowerCase()} Badge`;
    }
  }

  private getBadge(){
    switch(this.package.code) {
      case '001':
        return this.getBadgePath('silver');
      case '006':
        return this.getBadgePath('bronze');
      case '008':
      case '007':
        return this.getBadgePath('silver');
      case '002':
        return this.getBadgePath('gold');
      case '003':
      case '009':
        return this.getBadgePath('platinum');
      case '004':
      case '010':
        return this.getBadgePath('blue');
      case '011':
        return this.getBadgePath('temp');
      case '497':
        return this.getBadgePath('gold');
      case '498':
        return this.getBadgePath('bronze');
      case '499':
        return this.getBadgePath('distrupter');

      default: return '/assets/broken-path.svg'
    }
  }

  private getBadgePath(color: string) {
    return `/assets/images/marketing-package/${color}_badge${this.isSmall ? '-small' : ''}.svg`;
  }
}
