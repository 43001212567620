// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { catchError, delay, map, retryWhen, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { MarketingOrder } from '../models/marketing-order.model';
import { TemplateInstance } from '../models/template-instance.model';
import { TemplateInstanceMetaData } from '../models/template-meta-data.model';
import {
  OrderActionTypes,
  SelectProductTemplate,
  SelectProductTemplateComplete
} from '../state-mgmt/order/order.actions';
import { DummyText } from '../constants';
import { ProductCode } from '../models/product.code';

export class PreviewPdfRes {
  previewPdfUrl: string;

  constructor(model?: Partial<PreviewPdfRes>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}

export enum exportType { // for previewPdfUrl api 
  TEMPLATE_SPEC = 'Template-Spec'
}
@Injectable()
export class TemplateService {

  resource = 'template-info';
  disableSendForApprovalBtn:boolean = false;
  private readonly productTemplateCache: {[marketingOrderId: string]: {[productId: string] : Observable<TemplateInstance[]>} } = {};

  constructor(private apiService: ApiService, private store: Store<any>, private actions: Actions) { }

  /**
   * Returns the template info related to a given templateCode
   * @param templateCode The templateCode being requested
   * @param marketingOrderId
   */
  getTemplateInfo(templateCode: string, marketingOrderId: string): Observable<TemplateInstanceMetaData> {
    const param = marketingOrderId ? `?marketingOrderId=${marketingOrderId}` : ``;
    const url = `${this.resource}/${templateCode}${param}`;

    return this.apiService.get(`${url}`).pipe(map(metaData => new TemplateInstanceMetaData(metaData)));
  }
  /**
   * Returns the previewPdfUrl related to a given templateCode
   * @param templateCode The templateCode being requested
   */
  getTemplatePreviewPdfUrl(templateCode: string): Observable<PreviewPdfRes> {
    const url = `/template-specs/previewpdf?codes=${templateCode}&exportType=${exportType.TEMPLATE_SPEC}`;
    return this.apiService.get(`${url}`);
  }
  /**
   * This Method tries to check if the object is found on S3.
   * If object is not present in S3, currently Access deined excpetion is shown
   * because of the permissions. S3 bucket is not public but only object is public so 
   * Access denied exception is thrown.
   * This method handles the exception for 10 times for every 1000ms
   * Returns the Blob of S3
   * @param templateCode The templateCode being requested
   */
  verifyS3URLisReady(url: string): Observable<any> {
    const options = {
      supressLogErrors: true
    }
    return this.apiService.getBlobFromS3URL$(`${url}`, options).pipe(      
      map(response => response),
      catchError( error => {throw new Error('PDF Not Ready')}),
      retryWhen(errors => 
        errors.pipe(          
          delay(1000), take(10))
    ),
    )
  }
  /**
   * Returns the template info related to a given templateCode
   * @param templateCode The templateCode being requested
   * @param marketingOrderId
   */
  getTemplateData(templateCode: string): Observable<any> {
    const url = `${this.resource}/${templateCode}/template`;
    return this.apiService.get(`${url}`);
  }

  async updateTemplateData(codesAndValues: Array<any[]>) {
    const url = `template-specs/updateTemplates`;
    await this.apiService.put(`${url}`, codesAndValues).subscribe(
      values => {
        if(values.ok === 1) {
          console.log("Templates successfully updated. Number of records modified: ", values.nModified);
        }
      },
      error => {
        console.warn("An error has occured updating template values: ", error);
      }
    )
  }

  /**
   * Gets the templates related to a given product code
   * @param marketingOrderId
   * @param productCode The ProductCode to retrieve the templates for
   */
  getProductTemplates(marketingOrderId: string, productCode: string): Observable<TemplateInstance[]> {
    if (!this.productTemplateCache[marketingOrderId]) {
      // Initialize the product template cache for the given marketing order
      this.productTemplateCache[marketingOrderId] = {};
    }
    if(!this.productTemplateCache[marketingOrderId][productCode]) {
      // Filter down the available templates to the specific product code
      this.productTemplateCache[marketingOrderId][productCode] =  this.apiService.getV2(`orders/${marketingOrderId}/${productCode}/templates`).pipe(
        shareReplay(1)
      );
    }
    // Return the product templates for the given marketing order
    return this.productTemplateCache[marketingOrderId][productCode];
  }

  /**
   * Removes temmplates that are not suitable for the order.
   * @param order
   * @param templates
   */
  filterTemplatesForOrder(order: MarketingOrder, templates: TemplateInstance[] ) {
    return templates.filter(template => this.isTemplateSuitableForOrder(order, template));
  }

  /**
   * Returns true if the selectedTemplate can be used for the order.
   *
   * Templates may be used for both global-luxury and regular coldwell-banker listings.
   *
   * If the order is a global-luxury listing and the template is flagged for global luxury then it is suitable.
   *
   * If the order is NOT a global luxury listing and the template is flagged as a coldwell-banker template then it is
   * suitable.
   *
   * @param order
   * @param selectedTemplate
   */
  isTemplateSuitableForOrder(order: MarketingOrder, selectedTemplate: TemplateInstance) {
    return (order && order.listing && selectedTemplate) &&
      (
        // Print advertising templates can be used for any combo
        (selectedTemplate.productCode === ProductCode.PRINT_ADVERTISING)  ||
        (order?.listing?.globalLuxuryListing && selectedTemplate.globalLuxury) || // flagged GL
        (!order?.listing?.globalLuxuryListing && selectedTemplate.coldwellBanker) || // flagged CB

        // The old condition to satisfy data prior to adding coldwell banker
        (!order?.listing?.globalLuxuryListing && !selectedTemplate.globalLuxury && selectedTemplate.coldwellBanker === undefined)
      );
  }
  /**
   * Dispatches an update to select a product and creates a promise that will resolve
   * when the UpdateOrderComplete has been executed.
   */
  async selectTemplate(productCode: string, template: TemplateInstance): Promise<MarketingOrder> {
    const onTemplateSelected = new Promise<MarketingOrder>((resolve, reject) => {
      // Subscribe to the update order complete to be complete.
      // NOTE: Do this before dispatching action to avoid race conditions
      const subscription = this.actions.pipe(
        ofType<SelectProductTemplateComplete>(OrderActionTypes.SelectProductTemplateComplete)
      ).subscribe(orderComplete => {
        // Once order has been updated, resolve the Promise and unsubscribe
        resolve(orderComplete.payload);
        subscription.unsubscribe();
      }, error => {
        // On error, reject promise and unsubscribe
        reject(error);
        subscription.unsubscribe();
      });

      // Dispatch action after we have subscribed to it
      this.store.dispatch(new SelectProductTemplate(productCode, template));
    });

    return await onTemplateSelected;
  }

  async setTemplateInfoToProduct(order: MarketingOrder) {

    //return new Promise( (resolve, reject) => {
   let isTemplatesUpdated = false
    const productsList = order.selectedPackage?.products;
    const arrayPromises = [];
    for (let productIndex =0 ; productIndex < productsList?.length; productIndex++ ) {
      const eachProduct = productsList[productIndex];
      const prmoiseTemplates = this.getProductTemplates(order._id, eachProduct.code).pipe(
        map(productTemplates => (order.__t === 'LC') ? this.filterTemplatesForOrder(order, productTemplates) : []), //HACk templates for LC orders
        take(1),
      ).toPromise()
      arrayPromises.push(prmoiseTemplates)
    }
    const templates = await Promise.all(arrayPromises)
    templates.forEach( (productTemplates, index) => {
     const returnedTemplates = productTemplates.filter( selectedTemplate => productsList[index].selectedTemplateCode === selectedTemplate.code )
      if(returnedTemplates.length > 0) {
        productsList[index].selectedTemplate = returnedTemplates[0];
        isTemplatesUpdated = true;
      }
    //})
    })
  };

  async setTemplateFoSingleProduct(order: MarketingOrder, productCode: string)
  {
    const selectedProduct = order.selectedPackage.products.find(product => product.code === productCode);
    const templates = await this.getProductTemplates(order._id, productCode).pipe(
      map(productTemplates => this.filterTemplatesForOrder(order, productTemplates)),
      take(1),
    ).toPromise()
    const selectedTemplate = templates.find(template => template.code === selectedProduct.selectedTemplateCode)
    selectedProduct.selectedTemplate = selectedTemplate;
    return selectedProduct;
  }

  /**
   * Naming in case we want to add additional values to a template
   * on the fly. This can be done by adding to the promise chain.
   * Maybe move this out of the template service and into a directive?
   * @param templates
   * @returns
   */
  applyChangesToTemplates(templates): any {
    const promises = [];
    templates.forEach(template => {
      promises.push(this.getTemplateData( template.code ).toPromise());
    });
    return Promise.all(promises)
      .then( async templateData => {
        const templateUpdatePayload = [];
        templateData.forEach( (template,index) => {
          // store previous value of approximate max chars for comparison
          const previousValue = template.templateInfo?.approximateMaxChars || null;
          template.pages.forEach( page => {
            let bodies = page.pageItems.filter( item => item.label.includes('body[1]'))
            if(bodies.length === 0){
              bodies = page.pageItems.filter(pi => pi.type === 'Group').map(p => p.items).flat()
                .filter(item => item.label.includes('body[1]'));
            }
            bodies.forEach( body => {
                template.approximateMaxChars = this.approximateMaxChars(body);
              })
          })
          // Prepare payload to update template records.
          // Only add if an approximate max chars and the previous
          // values differ.
          if(
            template.approximateMaxChars
            && (template.approximateMaxChars !== previousValue)
          ) {
            templateUpdatePayload.push({
              code: templates[index].code,
              approximateMaxChars: template.approximateMaxChars
            })
          }
        })
        if(templateUpdatePayload.length){
          await this.updateTemplateData(templateUpdatePayload)
        }
        return templateData;
      });
  }

  /**
   * Creates dummy elements using a dummy string to approximate
   * the number of characters a text field will have in its associated
   * template
   * @param data
   * @returns
   */
  approximateMaxChars(data) {
    const element = this.generateElement(data);
    document.body.appendChild(element);

    let i = 0
    element.innerHTML += `${DummyText[i++]}`

    for (; i < DummyText.length; i++ ) {
      element.innerHTML += ` ${DummyText[i]}`;
      const pixelHeight = Math.round(data.rect.h);
      if (element.scrollHeight > pixelHeight) {
        break;
      }
    }


    const testLength = element.innerHTML.length;
    const lastWordLength = DummyText[i - 1].length;
    const maxChars = ((testLength - lastWordLength) / 10) * 10;
    // Remove the element as it is no longer needed
    document.body.removeChild(element);
    // Return updated approxMaxChar value if differs or null.
    return maxChars;
  }

  // Generates an element used for approximating the character count.
  generateElement(data) {
    const el: any = document.createElement('div', {});
    el.classList.add('generated-for-approximation');
    el.style = `visibility: hidden;
                width: ${Math.round(data.rect.w)}px;
                position: relative;
                font-size: ${data.text.pointSize}px;
                line-height: ${data.text.lineSpacing}px;
                text-rendering: geometricPrecision;
                text-size-adjust: none;
                margin: 0px!important;padding: 0px !important; border: none;`;

    return el;
  }

}
